import React, { } from "react";
import "./App.css";
import apple from "./assets/Apple_Music_icon.svg";
import tiktok from "./assets/TikTok_Logo.webp";
import instagram from "./assets/Instagram_logo_2016.svg";
import soundcloud from "./assets/soundcloud-circle.png";

function App() {
  return (
    <div className="main">
      <div className="left"></div>
      <div className="center">
        <div className="hero-group">
          <h1 className="hero hero-main">The Valley</h1>
          <a
            className="hero hero-btn"
            href="https://soundcloud.com/courtsiemusic/13264875-284c-4fb1-97d0-728d6f859320?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"
          >
            Book I
          </a>
        </div>
      </div>
      <div className="right">
        {" "}
        <div className="social-icon-set">
          <a
            href="https://soundcloud.com/courtsiemusic"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="nav-icon" src={soundcloud} alt="" />
          </a>
          <a
            href="https://www.tiktok.com/@courtneylyne"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="nav-icon tiktok" src={tiktok} alt="" />
          </a>
          <a
            href="https://music.apple.com/us/artist/courtsie/1707186360"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="nav-icon-square" src={apple} alt="" />
          </a>

          <a
            href="https://www.instagram.com/courtneylynejohnson"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="nav-icon-square" src={instagram} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
